'use client'

import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import ComponentErrorBoundary from '@components/component_error_boundary'
import OfferTileBrand from '@components/offer_tile_brand'
import OfferTileExclusiveFlag from '@components/offer_tile_exclusive_flag'
import OfferTileHeader from '@components/offer_tile_header'
import OfferTileLabels from '@components/offer_tile_labels'
import OfferTileLinkContainer from '@components/offer_tile_link_container'
import OfferTileTitle from '@components/offer_tile_title'
import { addSentryTag } from '@helpers/offer_tile'

const OfferTileTags = dynamic(() => import('@components/offer_tile_tags'), {
  ssr: false
})

const OfferTile = ({
  offer,
  pathSuffix,
  impressionContent,
  priority,
  onClick
}) => {
  return (
    <ComponentErrorBoundary
      addSentryTag={(scope) => addSentryTag(scope, offer)}
    >
      <OfferTileLinkContainer
        offer={offer}
        pathSuffix={pathSuffix}
        impressionContent={impressionContent}
        onClick={onClick}
      >
        <OfferTileExclusiveFlag offer={offer} />
        <OfferTileHeader
          offer={offer}
          priority={priority}
        />
        <OfferTileTags offer={offer} />
        <OfferTileTitle offer={offer} />
        <OfferTileBrand offer={offer} />
        <OfferTileLabels offer={offer} />
      </OfferTileLinkContainer>
    </ComponentErrorBoundary>
  )
}

OfferTile.propTypes = {
  offer: PropTypes.object.isRequired,
  pathSuffix: PropTypes.string.isRequired,
  impressionContent: PropTypes.shape({
    contentPosition: PropTypes.number,
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    groupPosition: PropTypes.number
  }),
  priority: PropTypes.bool,
  onClick: PropTypes.func
}

OfferTile.defaultProps = {
  impressionContent: null,
  priority: false,
  onClick: null
}

export default OfferTile
