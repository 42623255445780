import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'

const OfferTileBrand = ({ offer, mini }) => {
  if (!offer?.brand?.name) return null

  const renderMiniBrand = () => {
    if (!mini) return null

    return (
      <Typography
        variant='footnote'
        data-testid='offer-tile-brand'
      >
        {offer.brand.name}
      </Typography>
    )
  }

  const renderBrand = () => {
    if (mini) return null

    return (
      <Typography
        variant='subhead'
        className='relative mb-2 !text-grey-400'
        data-testid='offer-tile-brand'
      >
        {offer.brand.name}
      </Typography>
    )
  }

  return (
    <>
      {renderBrand()}
      {renderMiniBrand()}
    </>
  )
}

OfferTileBrand.defaultProps = {
  offer: {},
  mini: false
}

OfferTileBrand.propTypes = {
  offer: PropTypes.shape({
    brand: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  mini: PropTypes.bool
}

export default OfferTileBrand
