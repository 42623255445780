'use client'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'

import { getRedemptionClassText } from '@helpers/redemption'
import { useI18n } from '@hooks/use_i18n'

import {
  getOfferTypeLabels,
  shouldRenderLabels,
  getAdvertLabel
} from './helpers'

const OfferTileLabels = ({ offer }) => {
  const { t } = useI18n()
  const redemptionClassText = getRedemptionClassText(t, offer.redemptionClass)

  if (!shouldRenderLabels(offer)) return null

  const renderOfferLabel = (label, first) => {
    if (!label) return null

    return (
      <Typography
        variant='footnote'
        fontWeight='normal'
        className={clsx(
          'flex items-center p-1 mr-[3px] leading-4 !text-grey-400',
          !first &&
            'before:w-[3px] before:h-[3px] before:mr-[6px] before:mb-[3px] before:rounded-full before:bg-grey-400'
        )}
      >
        {label}
      </Typography>
    )
  }

  return (
    <div className='flex flex-wrap p-0'>
      {renderOfferLabel(getOfferTypeLabels(offer.contentType, t), true)}
      {renderOfferLabel(redemptionClassText)}
      {renderOfferLabel(offer.primaryCategory?.name)}
      {renderOfferLabel(getAdvertLabel(offer))}
    </div>
  )
}

OfferTileLabels.defaultProps = {
  offer: {
    contentType: '',
    redemptionClass: '',
    primaryCategory: {
      name: ''
    }
  }
}

OfferTileLabels.propTypes = {
  offer: PropTypes.shape({
    contentType: PropTypes.string,
    redemptionClass: PropTypes.string,
    primaryCategory: PropTypes.shape({
      name: PropTypes.string
    })
  })
}

export default OfferTileLabels
