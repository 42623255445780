'use client'

import PropTypes from 'prop-types'

import OfferTileLogo from '@components/offer_tile_logo'
import SafeImage from '@components/safe_image'

const OfferTileHeader = ({ offer, priority }) => {
  const renderImage = () => {
    return (
      <SafeImage
        src={offer?.defaultImageSmall}
        data-testid='offer-tile-image'
        alt={offer?.brand?.name}
        fill
        style={{ objectFit: 'cover' }}
        priority={priority}
      />
    )
  }

  const renderLogo = () => {
    return (
      <OfferTileLogo
        offer={offer}
        height={74}
        width={74}
      />
    )
  }

  return (
    <div
      className='relative h-[126px] overflow-hidden p-3 mb-[10px] rounded-1 bg-liquorice-200 xl:h-[180px]'
      data-testid='offer-header-container'
    >
      {renderImage()}
      <div className='absolute bottom-0 left-0 z-2 m-3 leading-[0]'>
        {renderLogo()}
      </div>
    </div>
  )
}

OfferTileHeader.propTypes = {
  offer: PropTypes.shape({
    defaultImageSmall: PropTypes.string,
    brand: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string
    })
  }).isRequired,
  priority: PropTypes.bool
}

OfferTileHeader.defaultProps = {
  priority: false
}

export default OfferTileHeader
