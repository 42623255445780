import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'

import { useI18n } from '@hooks/use_i18n'

import { getTitle } from './helpers'

const OfferTileTitle = ({ offer, mini }) => {
  const { t } = useI18n()
  const title = getTitle(offer, t)

  const renderMiniTitle = () => {
    if (!mini) return null

    return (
      <Typography
        variant='subhead'
        component='h4'
        fontWeight='semibold'
        className='overflow-hidden max-h-[39px]'
        data-testid='offer-tile-title'
      >
        {title}
      </Typography>
    )
  }

  const renderTitle = () => {
    if (mini) return null

    return (
      <Typography
        variant='headline'
        component='h4'
        fontWeight='semibold'
        className='overflow-hidden max-h-[45px] mb-1 text-liquorice-500'
        data-testid='offer-tile-title'
      >
        {title}
      </Typography>
    )
  }

  return (
    <div data-testid='offer-tile-title'>
      {renderTitle()}
      {renderMiniTitle()}
    </div>
  )
}

OfferTileTitle.propTypes = {
  offer: PropTypes.shape({
    title: PropTypes.string.isRequired,
    expired: PropTypes.bool.isRequired
  }).isRequired,
  mini: PropTypes.bool
}

OfferTileTitle.defaultProps = {
  mini: false
}

export default OfferTileTitle
