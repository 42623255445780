export const TERMS_ICON_TYPES = {
  INCLUSION: 'INCLUSION',
  EXCLUSION: 'EXCLUSION',
  INFORMATION: 'INFORMATION'
}

export const GRAD_PAGE_SELECTED_OFFER_BACKGROUND_COLOUR = 'bg-huckleberry-300'
export const NON_GRAD_PAGE_SELECTED_OFFER_BACKGROUND_COLOUR = 'bg-violet-500'

export const GRAD_PAGE_SELECTED_OFFER_OUTLINE_COLOUR =
  'focus:outline-huckleberry-300'
export const NON_GRAD_PAGE_SELECTED_OFFER_OUTLINE_COLOUR =
  'focus:outline-violet-500'
export const BUTTON_STYLES =
  'flex justify-between !w-full !pt-4 !pb-3 text-left rounded-0 focus:outline focus:outline-1'

export const CHEVRON_OPEN_ROTATION = 'rotate-180'
export const CHEVRON_CLOSED_ROTATION = 'rotate-0'
export const CHEVRON_ICON_STYLES = 'transition-transform stroke-grey-500'

export const HIDDEN_STYLE = 'hidden'
