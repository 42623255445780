'use client'

import { withScope } from '@sentry/nextjs'
import PropTypes from 'prop-types'
import { Component } from 'react'

import { captureExceptionError } from '@helpers/capture_exception_error'

class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      scope.setExtras(errorInfo)
      scope.setTag('sb_handler', 'ComponentErrorBoundary')
      this.props.addSentryTag(scope)
      captureExceptionError(error)
    })
  }

  render() {
    if (this.state.hasError) return this.props.fallback

    return this.props.children
  }
}

ComponentErrorBoundary.propTypes = {
  addSentryTag: PropTypes.func,
  children: PropTypes.any.isRequired,
  fallback: PropTypes.any
}

/* istanbul ignore next */
ComponentErrorBoundary.defaultProps = {
  addSentryTag: () => {},
  fallback: null
}

export default ComponentErrorBoundary
