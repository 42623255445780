import clsx from 'clsx'
import PropTypes from 'prop-types'

import SafeImage from '@components/safe_image'

const OfferTileLogo = ({ offer, height, width, className }) => {
  return (
    <SafeImage
      className={clsx('rounded-3 bg-white-300', className)}
      alt={offer?.brand?.name}
      src={offer?.brand?.logo}
      data-testid='offer-tile-logo'
      width={height}
      height={width}
    />
  )
}

OfferTileLogo.propTypes = {
  offer: PropTypes.shape({
    brand: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string
    })
  }).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  className: PropTypes.string
}

OfferTileLogo.defaultProps = {
  className: ''
}

export default OfferTileLogo
