import { REDEMPTION_CLASSES } from '@constants/offers'
import { IN_APP_REDEMPTION_CLASS } from '@constants/redemption'
import { isInApp } from '@helpers/offers'

export const shouldRenderRedemptionClass = ({ expired, redemptionClass }) =>
  !!(!expired && redemptionClass)

export const getRedemptionClass = (offer) => {
  if (!offer?.redemptionClass) return ''

  if (isInApp(offer)) return IN_APP_REDEMPTION_CLASS

  return offer.redemptionClass
}

export const getRedemptionText = (t, offer) => {
  const redemptionClass = getRedemptionClass(offer)
  const translationKey = `d_${redemptionClass.toLowerCase()}_at_brand`
  const brand = offer?.brand?.name

  return (
    t(translationKey, { brand }) ||
    `${redemptionClass} ${t('offers.offer-card.at', {
      fallback: 'at'
    })} ${brand}`
  )
}

export const getRedemptionClassText = (t, redemptionClass) => {
  if (redemptionClass === REDEMPTION_CLASSES.INSTORE) {
    return t('d_instore', { fallback: REDEMPTION_CLASSES.INSTORE })
  }

  return t('d_online', { fallback: REDEMPTION_CLASSES.ONLINE })
}
