'use client'

import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'
import { useI18n } from '@hooks/use_i18n'

import { ONLY_ON, SB_LOGO_ALT } from './constants'

const OfferTileExclusiveFlag = ({ offer }) => {
  const { t } = useI18n()
  if (!offer?.baseRedemptionType?.exclusive) return null

  const renderText = () => {
    return (
      <Typography
        variant='caption2'
        component='p'
        fontWeight='semibold'
        colour='white-300'
        className='hidden xl:block'
      >
        {t('d_only_on_tag', { fallback: ONLY_ON })}
      </Typography>
    )
  }

  const renderSBLogo = () => {
    return (
      <SafeImage
        alt={SB_LOGO_ALT}
        src={withAssetPrefix('images/sb_small_light_logo.svg')}
        width={22}
        height={12}
      />
    )
  }

  return (
    <span
      className='absolute flex-center w-max left-0 z-3 gap-1 rounded-tl-1 rounded-br-1 p-2 bg-violet-500 xl:py-2 xl:px-4'
      data-testid='offer_tile_exclusive_flag'
    >
      {renderText()}
      {renderSBLogo()}
    </span>
  )
}

OfferTileExclusiveFlag.propTypes = {
  offer: PropTypes.shape({
    baseRedemptionType: PropTypes.shape({
      exclusive: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired
}

export default OfferTileExclusiveFlag
