import { OFFER_TYPE_LABELS, SPONSORED_TEXT_PLACEHOLDER } from './constants'

export const getOfferTypeLabels = (offerType, t) => {
  if (!OFFER_TYPE_LABELS[offerType])
    return t('d_for_anyone_tag', { fallback: OFFER_TYPE_LABELS.anyone })

  return t('d_for_students_only_tag', {
    fallback: OFFER_TYPE_LABELS[offerType]
  })
}

export const shouldRenderLabels = (offer) => {
  return !!(
    offer?.contentType ||
    offer?.redemptionClass ||
    offer?.primaryCategory?.name ||
    offer?.isAdvert
  )
}

export const getAdvertLabel = (offer) => {
  if (!offer.isAdvert) {
    return null
  }

  return SPONSORED_TEXT_PLACEHOLDER
}
