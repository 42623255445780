import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useRef } from 'react'

import {
  getImpressionRef,
  reportErrorHandler,
  trackImpressionClick
} from '@helpers/impression_tracking'
import { getOfferUrl } from '@helpers/offers'
import { useCCG } from '@hooks/use_ccg'
import useImpressionTracking from '@hooks/use_impression_tracking'

import { getImpressionLoadData } from './helpers'

const OfferTileLinkContainer = ({
  offer,
  pathSuffix,
  impressionContent,
  children,
  onClick,
  className
}) => {
  const ref = useRef(null)
  const { isGradBeansPage } = useCCG()

  const impressionLoadData = getImpressionLoadData(offer, impressionContent)

  const { impressionId } = useImpressionTracking(
    impressionLoadData,
    getImpressionRef(ref, !!impressionLoadData),
    reportErrorHandler,
    offer.kevelTracking
  )

  const handleClick = () => {
    trackImpressionClick(impressionId, offer?.kevelTracking)
    onClick?.()
  }

  return (
    <article
      className={clsx(
        'lg:h-full lg:relative lg:overflow-hidden lg:transition-all lg:duration-100 lg:ease-linear lg:my-0 lg:mx-[-7px] lg:p-3 rounded-2 lg:outline-grey-100 lg:hover:bg-white-300 lg:hover:outline-1 lg:hover:outline lg:hover:outline-grey-100 lg:hover:shadow-2',
        className
      )}
      itemProp='offers'
      itemScope=''
      itemType='http://schema.org/Offer'
      ref={ref}
    >
      <a
        className='relative'
        href={getOfferUrl(offer, pathSuffix, isGradBeansPage)}
        onClick={handleClick}
      >
        {children}
      </a>
    </article>
  )
}

OfferTileLinkContainer.defaultProps = {
  impressionContent: null,
  onClick: null,
  className: ''
}

OfferTileLinkContainer.propTypes = {
  children: PropTypes.any.isRequired,
  offer: PropTypes.shape({
    kevelTracking: PropTypes.shape({
      trackingUrls: PropTypes.string
    })
  }).isRequired,
  pathSuffix: PropTypes.string.isRequired,
  impressionContent: PropTypes.shape({
    contentPosition: PropTypes.number,
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    groupPosition: PropTypes.number
  }),
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default OfferTileLinkContainer
