import {
  CHEVRON_CLOSED_ROTATION,
  CHEVRON_OPEN_ROTATION,
  GRAD_PAGE_SELECTED_OFFER_BACKGROUND_COLOUR,
  GRAD_PAGE_SELECTED_OFFER_OUTLINE_COLOUR,
  HIDDEN_STYLE,
  NON_GRAD_PAGE_SELECTED_OFFER_BACKGROUND_COLOUR,
  NON_GRAD_PAGE_SELECTED_OFFER_OUTLINE_COLOUR,
  TERMS_ICON_TYPES
} from '@constants/offer_tile'
import { withAssetPrefix } from '@helpers/assets'

export const hasCovidFlag = (activeFlags) => {
  const activeFlag = getOfferActiveFlag(activeFlags)
  if (!activeFlag?.flag || !activeFlag.live) return false

  return activeFlag.flag.name === 'COVID-19'
}

export const getOfferActiveFlag = (activeFlags) => {
  if (activeFlags?.edges?.length > 0) {
    return activeFlags.edges[0].node
  }
  return null
}

export const parseTermsAndConditions = (offer) => {
  if (!offer?.termsAndConditions) return []

  let parsedTerms
  try {
    parsedTerms = JSON.parse(offer.termsAndConditions)
  } catch {
    parsedTerms = []
  }
  return parsedTerms
}

export const addSentryTag = (scope, offer) => {
  scope.setTag('offerUid', offer?.uid)
}

export const getTermsIcon = (item) => {
  if (item?.type === TERMS_ICON_TYPES.INCLUSION)
    return withAssetPrefix('images/terms_icons/tick.svg')

  if (item?.type === TERMS_ICON_TYPES.EXCLUSION)
    return withAssetPrefix('images/terms_icons/cross.svg')

  return withAssetPrefix('images/terms_icons/info.svg')
}

export const getSelectedOfferBackgroundColour = (isGradBeansPage) => {
  if (isGradBeansPage) return GRAD_PAGE_SELECTED_OFFER_BACKGROUND_COLOUR

  return NON_GRAD_PAGE_SELECTED_OFFER_BACKGROUND_COLOUR
}

export const getButtonOutlineColour = (isGradBeansPage) => {
  if (isGradBeansPage) return GRAD_PAGE_SELECTED_OFFER_OUTLINE_COLOUR

  return NON_GRAD_PAGE_SELECTED_OFFER_OUTLINE_COLOUR
}

export const getChevronIconRotationStyle = (isOpen) => {
  if (isOpen) return CHEVRON_OPEN_ROTATION

  return CHEVRON_CLOSED_ROTATION
}

export const getTacHiddenStyles = (isOpen) => !isOpen && HIDDEN_STYLE
